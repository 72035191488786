import { defaultTheme } from 'constants/theme/defaultTheme';
import styled from 'styled-components';

export const Styles = {
  IconWrapper: styled.div`
    align-self: flex-start;
    margin-right: ${defaultTheme.spacing(2)};
  `,
  PreviewHeader: styled.div`
    display: flex;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: ${defaultTheme.spacing(6)};
    justify-content: space-between;
  `,
  CloseButton: styled.button`
    cursor: pointer;
    background-color: transparent;
    border: none;
  `,
  StepWrapper: styled.div`
    align-self: flex-end;
  `,
  StepperWrapper: styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  `,
  Wrapper: styled.div`
    width: ${defaultTheme.spacing(240)};
    display: flex;
    height: 90vh;
    max-height: 692px;
    background-color: ${defaultTheme.colors.white};
    border-radius: 8px;
  `,
  FormWrapper: styled.div`
    flex: 3;
    display: flex;
    flex-direction: column;
  `,

  Header: styled.div`
    position: relative;
    padding: ${defaultTheme.spacing(4)} ${defaultTheme.spacing(6)};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.separator};
  `,

  Icon: styled.svg`
    margin-right: ${defaultTheme.spacing(4)};
  `,

  Title: styled.h1`
    font-weight: 500;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.deepBlue};
  `,
  Subtitle: styled.p`
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.darkGrey};
  `,

  Close: styled.div`
    font-size: 13px;
    cursor: pointer;
  `,
  HeaderContent: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,

  PreviewPanel: styled.div`
    position: relative;
    flex: 2;
    background-color: ${({ theme }) => theme.colors.deepBlue};
    border-radius: 0 8px 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  Form: styled.form<{ removePadding?: boolean }>`
    padding: ${defaultTheme.spacing(4)} ${defaultTheme.spacing(10)};
    flex: 1;
    overflow: auto;
    ${props => props.removePadding && `padding: 0;`}
  `,

  Footer: styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    padding: ${defaultTheme.spacing(4)};
    border-radius: 0 0 0 8px;
  `,

  FooterError: styled.div`
    position: absolute;
    background-color: ${({ theme }) => theme.colors.white};
    width: 100%;
    bottom: ${defaultTheme.spacing(20)};
    left: 0;
    padding: ${defaultTheme.spacing(7)} ${defaultTheme.spacing(10)};
  `,

  BackButton: styled.p`
    margin-left: ${defaultTheme.spacing(4)};
    font-weight: 500;
    font-size: 14px;
    &:hover {
      cursor: pointer;
    }
    color: ${({ theme }) => theme.colors.deepBlue};
  `,

  FooterInner: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,

  FooterRight: styled.div`
    display: flex;
    align-items: center;
  `,

  Text: styled.p`
    font-weight: 500;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.alertRed};
  `,

  Small: styled.p`
    margin-left: 0;
    font-weight: 500;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.darkGrey};
  `,
};
