import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Input } from 'atoms/Input/Input';
import { Button } from 'atoms/Button/Button';
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  CognitoError,
  getAuthenticatedUser,
  getUserGroup,
  signIn,
  signOut,
  USER_GROUPS,
} from 'services/auth';
import { LoginFormSchema } from './LoginForm.schema';
import { LoginFlowSteps } from '../../LoginFlow';
import { CognitoUser } from '@aws-amplify/auth';
import { Description, ForgotPassword, StyledForm, Title } from '../../styles';
import { useRouter } from 'next/router';
import { PATHS } from 'constants/paths';
import { Error } from 'atoms/Error';
import { Field } from 'atoms/Field/Field';
import { useAuth } from 'contexts/AuthProvider/AuthProvider';
import { TEST_IDS } from 'constants/testIds';
import { Spacer } from 'components/Spacer/Spacer';
import { Styles } from './styles';

type LoginFormProps = {
  setStep: Dispatch<SetStateAction<LoginFlowSteps>>;
  setUser: Dispatch<SetStateAction<CognitoUser | undefined>>;
};

export const LoginForm: React.FC<LoginFormProps> = ({ setStep, setUser }) => {
  const intl = useIntl();
  const router = useRouter();
  const [formError, setFormError] = useState<string | undefined>();
  const { setUser: setAuthUser } = useAuth();
  const { handleSubmit, handleChange, handleBlur, values, touched, errors, isValid, isSubmitting } =
    useFormik({
      initialValues: {
        email: '',
        password: '',
      },
      initialErrors: {
        email: '',
        password: '',
      },
      onSubmit: async values => {
        setFormError(undefined);

        const trimmedEmail = values.email.trim();

        try {
          const response = await signIn(trimmedEmail, values.password);
          const group = await getUserGroup(response);

          if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
            setUser(response);
            setStep(LoginFlowSteps.RESET_PASSWORD);
            return;
          }

          if (
            response.message === 'Authentication Error' ||
            !group ||
            (group !== USER_GROUPS.BUSINESS_UNIT_ADMIN && group !== USER_GROUPS.VTAIL_ADMIN)
          ) {
            setFormError(intl.formatMessage({ id: 'login.errors.authError' }));

            await signOut();
            return;
          }

          setAuthUser(response.attributes);

          // Log in the user
          await getAuthenticatedUser();
          const redirectPath =
            group === USER_GROUPS.VTAIL_ADMIN ? PATHS.ADMIN_COMPANIES : PATHS.EMPLOYEES;
          return router.push(redirectPath);
        } catch (error) {
          const cognitoError = error as CognitoError;
          if (!(cognitoError && cognitoError.code)) {
            setFormError(intl.formatMessage({ id: 'login.errors.authError' }));
            return;
          }

          if (
            cognitoError.code === 'NotAuthorizedException' ||
            cognitoError.code === 'UserNotFoundException'
          ) {
            setFormError(intl.formatMessage({ id: 'login.errors.emailPasswordIncorrect' }));
          }
        }
      },
      validationSchema: LoginFormSchema,
    });

  const handleChangeInput = useCallback(
    e => {
      setFormError(undefined);
      handleChange(e);
    },
    [handleChange],
  );

  return (
    <>
      <Title data-testid="title">
        <FormattedMessage id="login.title" />
      </Title>
      <Description>
        <FormattedMessage id="login.description" />
      </Description>
      <StyledForm onSubmit={handleSubmit} autoComplete="off">
        <Field>
          <Input
            data-testid={TEST_IDS.signInForm.email}
            name="email"
            type="text"
            placeholder={intl.formatMessage({ id: 'login.email-placeholder' })}
            onChange={handleChangeInput}
            onBlur={handleBlur}
            value={values.email}
          />
          {touched.email && errors.email && (
            <Error data-testid="email-errors">{errors.email}</Error>
          )}
        </Field>
        <Spacer.Column numberOfSpaces={4} />
        <Field>
          <Input
            data-testid={TEST_IDS.signInForm.password}
            name="password"
            type="password"
            placeholder={intl.formatMessage({ id: 'login.password-placeholder' })}
            onChange={handleChangeInput}
            onBlur={handleBlur}
            value={values.password}
          />
          {touched.password && errors.password && (
            <Error data-testid="password-errors">{errors.password}</Error>
          )}
        </Field>
        {formError ? (
          <>
            <Spacer.Column numberOfSpaces={5} />
            <Styles.FormError>
              <Error data-testid="form-errors" noneAbsolute>
                {formError}
              </Error>
            </Styles.FormError>
          </>
        ) : (
          <Spacer.Column numberOfSpaces={11} />
        )}
        <Button
          text={isSubmitting ? 'Loading...' : intl.formatMessage({ id: 'login.login-button' })}
          data-testid={TEST_IDS.signInForm.button}
          block
          type="submit"
          disabled={!isValid || isSubmitting}
        />
        <ForgotPassword onClick={() => setStep(LoginFlowSteps.FORGOTTEN_PASSWORD)}>
          <FormattedMessage id="login.forgotPassword" />
        </ForgotPassword>
      </StyledForm>
    </>
  );
};

export default LoginForm;
