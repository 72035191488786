import styled from 'styled-components';
import { defaultTheme } from 'constants/theme/defaultTheme';

export const Styles = {
  FormError: styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: ${defaultTheme.spacing(2)};
  `,
};
